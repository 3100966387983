<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
export default {
  name: 'bigWheel',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/turntable/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          label: '',
          key: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },

        {
          dataIndex: 'zjCount',
          title: '中奖情况',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#027DB4;font-weight:500">{`${text ? text : 0}/${records.cjCount}（${
                records.percentage
              }%）`}</div>
            )
          },
          onExport: (text, records) => {
            return `${text}/${records.cjCount}（${records.percentage}%）`
          }
        },
        {
          dataIndex: 'cjCount',
          title: '抽奖次数',
          align: 'left'
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '8%',
          typeData: ({ records }) => {
            return [
              {
                display: records.bodyStatus == '1',
                name: '设置主体',
                onClick: () =>
                  this.$router.push(`/marketingCenter/setMain?moduleType=${records.systemCode}&turnId=${records.id}`)
              },
              {
                display: true,
                name: '中奖情况',
                onClick: () => {
                  this.$router.push('/marketingCenter/bigWheelDetail/bigTurntable?id=' + records.id)
                }
              },
              {
                display: true,
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/turntable/${records.upDown == 1 ? 'up' : 'down'}?id=${records.id}`
                  })
                }
              },
              {
                display: true,
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/bigWheelDetail?id=' + records.id)
              },
              {
                display: true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/turntable?id=${records.id}`
                  })
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            getAction('/turntableModule/moduleList', {}, '/api').then(res => {
              if (res.code == 200 && res.data.length > 0) {
                this.$router.push('/marketingCenter/bigWheelDetail')
              } else {
                this.$message.warning('转盘位置已无，不可添加！')
              }
            })
          }
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/turntable/deleteBatch',
                  params: { idList: idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/turntable/upBatch',
                  params: { idList: idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/turntable/downBatch',
                  params: { idList: idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
